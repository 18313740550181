<template>
  <div class="GradeQuality">
    <template v-if="showIndexQuestion">
      <div class="mark-header">
        <div class="header-go-back" @click="goback()">
          <i class="el-icon-arrow-left"></i>返回质量列表
        </div>
        <div class="mark-title">
          主观题：{{ searchQuestionNum }}

          <template v-if="searchTeacher"
            >教师：{{ indexQuestion.teacherName }}</template
          >
          获取信息 — 全部抽查结果
        </div>
        <div></div>
      </div>
      <el-table
        :key="tableKey"
        v-loading="listLoading"
        :data="monitorCheckResultData"
        border
        style="width: 100%"
      >
        <el-table-column prop="teacherName" label="阅卷教师" align="center">
        </el-table-column>
        <el-table-column prop="rowNum" label="批阅顺序" align="center">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="批阅时间"
          :show-overflow-tooltip="true"
          align="center"
          width="200px"
        >
        </el-table-column>
        <el-table-column prop="score" label="给分" align="center">
        </el-table-column>
        <el-table-column prop="againScore" label="重评给分" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.againScore || scope.row.againScore == 0">
              {{ scope.row.againScore }}
            </template>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="改分" align="center">
          <template slot-scope="scope">
            <template
              v-if="scope.row.changeScore || scope.row.changeScore == 0"
            >
              {{ scope.row.changeScore }}
            </template>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="抽查结果" align="center">
          <template slot-scope="scope">
            <!-- <template v-if="scope.row.againScore || scope.row.againScore == 0">
              重评给分
            </template>
            <template v-else> -->
            <template v-if="!scope.row.state"> 未抽查 </template>
            <template v-if="scope.row.state == 1"> 通过 </template>
            <template v-if="scope.row.state == 2"> 打回 </template>
            <template v-if="scope.row.state == 3"> 改分 </template>
            <!-- </template> -->
          </template>
        </el-table-column>
        <el-table-column prop="score" label="处理结果" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.isHandle">已处理</template>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column
          prop="extractTime"
          label="抽查时间"
          :show-overflow-tooltip="true"
          width="200px"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="extractUserName" label="抽查人员" align="center">
        </el-table-column>
        <el-table-column prop="address" label="答题卡" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showQuestionAw(scope.row)">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </template>
    <template v-else>
      <div class="search-box">
        <div>
          <!-- {{ questionOrderList }} -->
          题目：
          <el-select v-model="webId" placeholder="全部题目" @change="checkList">
            <template v-for="(item, i) in questionOrderList">
              <el-option
                :key="i"
                :label="item.label"
                :value="item.webId"
                :disabled="item.isChild > 0"
              >
              </el-option>
            </template>
          </el-select>
          阅卷老师：
          <el-select
            v-model="teacherId"
            placeholder="所有老师"
            @change="checkTeacher"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          v-if="$route.query.markType != 2"
          type="primary"
          :loading="btnLoading"
          @click="exportQuality"
        >
          导出
        </el-button>
      </div>
      <el-table
        v-loading="listLoading"
        :data="qualityList"
        border
        style="width: 100%"
      >
        <el-table-column prop="questionNum" label="题目" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showOneQuestion(scope.row)">{{
              scope.row.questionNum
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="teacherName"
          :show-overflow-tooltip="true"
          label="阅卷教师"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="showOneQuestion(scope.row, 1)">{{
              scope.row.teacherName
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="timeUseAvg" label="平均给分" align="center">
          <template slot-scope="scope">
            {{ scope.row.scoreAvg | setFilexd }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="平均批阅时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.timeUseAvg | setFilexd }}
            <!-- {{ scope.row.timeUseAvg ? scope.row.timeUseAvg.toFixed(2) : 0 }} -->
          </template>
        </el-table-column>
        <el-table-column prop="validCount" label="有效卷" align="center">
          <template slot-scope="scope">
            {{
              scope.row.validCount
                ? (scope.row.validCount * 100).toFixed(2) + "%"
                : 0
            }}
          </template>
        </el-table-column>
        <el-table-column prop="completeCount" label="完成量" align="center">
        </el-table-column>
        <el-table-column prop="extractCount" label="抽查量" align="center">
        </el-table-column>
        <el-table-column prop="extractRate" label="抽查率" align="center">
          <template slot-scope="scope">
            {{
              scope.row.extractRate
                ? (scope.row.extractRate * 100).toFixed(2) + "%"
                : 0
            }}
          </template>
        </el-table-column>

        <el-table-column prop="modifyCount" label="改分量" align="center">
        </el-table-column>
        <el-table-column prop="backCount" label="打回量" align="center">
        </el-table-column>
        <el-table-column prop="processedCount" label="已处理量" align="center">
        </el-table-column>
        <el-table-column prop="passRate" label="通过率" align="center">
          <template slot-scope="scope">
            {{ scope.row.passRate | setToFilexd }} %
          </template>
        </el-table-column>
        <el-table-column prop="extractRate" label="抽查" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.extractRate == 1">已完成抽查</template>
            <el-button v-else type="text" @click="showQuestion(scope.row)"
              >抽查</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
import { getObj } from "@/core/api/exam/exam";
import {
  monitorQualityList,
  getQuestionTeacher,
  monitorCheckResult,
  questionTeacher,
  exportQuality,
} from "@/core/api/exam/paperMonitor";
import { getStore, setStore } from "@/core/util/store";
import { mapGetters } from "vuex";
import { showSubject, fileDownloadByUrl } from "@/core/util/util";
//
export default {
  name: "GradeQuality",
  data() {
    return {
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      listLoading: false,
      btnLoading: false,
      examPaperId: "",
      questionNum: "",
      teacherId: "",
      teacherIdKey: "",
      paperList: [],
      qualityList: [],
      questionOrderList: [],
      teacherList: [],
      indexTeacher: {},
      showIndexQuestion: false,
      indexQuestion: {},
      nowQuestion: {
        webId: 0,
      },
      webId: 0,
      monitorCheckResultData: [],
      tableKey: 1,
      // 单个搜索的时候
      searchTeacher: "",
      searchQuestionNum: "",
      tabKey: 1,
      schoolList: [],
      schoolId: "",
    };
  },
  computed: {
    ...mapGetters(["GET_TEACHER_ROLE_LIST", "GET_SCHOOL_LIST"]),
  },
  watch: {
    GET_SCHOOL_LIST: {
      deep: true,
      handler() {
        this.schoolList = this.GET_SCHOOL_LIST;
        if (this.schoolList.length > 0) {
          this.schoolId = "-1";
        }
      },
    },
  },
  created() {
    this.userInfo = getStore({ name: "userInfo" });
    this.schoolList = this.GET_SCHOOL_LIST;
    if (this.schoolList.length > 0) {
      this.schoolId = "-1";
    }
    this.examPaperId = Number(this.$route.query.examPaperId);
    this.setSearchList();
    this.getQuestionTeacher(1);
  },
  methods: {
    exportQuality() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.nowQuestion.value,
        sheetDetailId: this.nowQuestion.sheetDetailId,
        teacherId: this.teacherId,
      };
      if (this.$route.query.examType == 1) {
        data.schoolId = this.schoolId || "-1";
      }
      if (this.userInfo.roleId != 6) {
        data.schoolId = getStore({ name: "school_id" });
      }

      this.btnLoading = true;
      exportQuality(data)
        .then((res) => {
          // console.log(res);
          fileDownloadByUrl(res.data.data.url, res.data.data.name);
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    setSearchList() {
      let pageInfo = getStore({ name: "GradeQuality_info" });
      // console.log(pageInfo);
      if (
        pageInfo &&
        pageInfo.questionNum &&
        this.examPaperId == pageInfo.examPaperId
      ) {
        this.webId = pageInfo.webId;
        this.teacherId = pageInfo.teacherId;
        this.pageIndex = pageInfo.current;
        this.pageSize = pageInfo.size;
      }
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      if (this.showIndexQuestion) {
        this.monitorCheckResult();
      } else {
        this.monitorQualityList();
      }
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      if (this.showIndexQuestion) {
        this.monitorCheckResult();
      } else {
        this.monitorQualityList();
      }
      // console.log(`当前页: ${val}`);
    },
    selectChange() {
      this.pageIndex = 1;
      this.monitorQualityList();
    },
    showSubject(v) {
      return showSubject(v, 8);
    },
    monitorCheckResult() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.searchQuestionNum,
        teacherId: this.searchTeacher,
        current: this.pageIndex,
        size: this.pageSize,
        sheetDetailId: this.indexQuestion.sheetDetailId,
        type: this.indexQuestion.type,
      };
      //
      // if (data.questionNum) {
      //   this.questionOrderList.map((item) => {
      //     if (item.value == this.searchQuestionNum) {
      //       data.type = item.type;
      //     }
      //   });
      // }
      this.listLoading = true;
      monitorCheckResult(data)
        .then((res) => {
          // this. = res.data.data;
          this.listLoading = false;
          this.monitorCheckResultData = res.data.data.records;
          this.total = res.data.data.total;
          this.tableKey = new Date().getTime();
          // console.log(res);
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    goback() {
      this.pageIndex = 1;
      this.tabKey = new Date().getTime();
      this.showIndexQuestion = false;
      this.monitorQualityList();
    },
    showOneQuestion(item, type) {
      this.showIndexQuestion = true;
      this.pageIndex = 1;
      this.tabKey = new Date().getTime();
      if (!type) {
        this.searchQuestionNum = item.questionNum;
        this.searchTeacher = "";
      } else {
        this.searchQuestionNum = item.questionNum;
        this.searchTeacher = item.teacherId;
      }
      // console.log();

      this.indexQuestion = item;
      this.questionOrderList.map((index) => {
        if (index.label == item.questionNum) {
          this.indexQuestion.type = index.type;
        }
      });
      this.monitorCheckResult();
    },
    showQuestionAw(item) {
      let data = {
        imgUrl: item.answerUrl,
      };

      if (!item.answerUrl) {
        this.$message({
          message: "当前试题没有试题图片！",
          type: "warning",
        });
        return;
      }
      const routeData = this.$router.resolve({
        name: "ShowQuestionImgs",
        query: data,
      });
      window.open(routeData.href, "_blank");
    },
    showQuestion(item) {
      let data = {
        id: item.id,
        examPaperId: item.examPaperId,
        questionNum: item.questionNum,
        sheetDetailId: item.sheetDetailId,
        teacherId: item.teacherId,
        path: "GradeQuality",
      };
      data.markType = this.$route.query.markType;
      if (item.questionNum) {
        this.questionOrderList.map((index) => {
          if (index.label == item.questionNum) {
            data.type = index.type;
          }
        });
      }
      this.$router.push({
        name: "ShowCheckPaper",
        query: data,
      });
    },
    checkList() {
      this.nowQuestion = this.questionOrderList.filter(
        (item) => item.webId == this.webId
      )[0];
      // console.log(this.nowQuestion);
      this.questionTeacher();
      this.monitorQualityList();
    },
    checkTeacher() {
      this.indexTeacher = this.teacherList.filter(
        (item) => item.key == this.teacherIdKey
      )[0];

      this.monitorQualityList();
    },
    // 获取当前试题的列表
    questionTeacher() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.nowQuestion.value,
        sheetDetailId: this.nowQuestion.sheetDetailId,
      };
      if (!this.nowQuestion.webId) {
        this.teacherList = [{ label: "所有老师", value: "-1" }];
        this.teacherId = "-1";
        return;
      }
      let pageInfo = getStore({ name: "GradeQuality_info" });
      questionTeacher(data).then((res) => {
        this.teacherList = [{ label: "所有老师", value: "-1" }];

        res.data.data.forEach((item) => {
          if (
            pageInfo &&
            pageInfo.examPaperId == this.examPaperId &&
            pageInfo.teacherId == item.id
          ) {
            this.teacherId = pageInfo.teacherId;
          }
          this.teacherList.push({
            label: item.name,
            value: item.id,
          });
        });
        //
        this.teacherId = this.teacherId || "-1";
      });
    },
    getQuestionTeacher(type) {
      let data = {
        examPaperId: this.examPaperId,
        type: type,
      };
      getQuestionTeacher(data).then((res) => {
        if (type == 1) {
          this.questionOrderList = [
            { value: "", label: "全部", isChild: 0 },
          ].concat(res.data.data);
          this.questionOrderList = this.questionOrderList.map((item, index) => {
            item.webId = index;
            return item;
          });
          if (this.webId) {
            this.nowQuestion = this.questionOrderList.filter(
              (item) => item.webId == this.webId
            )[0];
          }
          if (this.questionOrderList.length > 0) {
            this.monitorQualityList();
            this.questionTeacher();
          }
        }
        // if (type == 2) {
        //   this.teacherList = [];
        //   let Array = [];
        //   res.data.data.forEach((item, index) => {
        //     item.key = index;
        //     if (Array.indexOf(item.value) == -1) {
        //       Array.push(item.value);
        //       this.teacherList.push(item);
        //     }
        //   });
        // }
      });
    },
    monitorQualityList() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.nowQuestion.value,
        sheetDetailId: this.nowQuestion.sheetDetailId,
        type: this.nowQuestion.type,
        teacherId: this.teacherId || "-1",
        current: this.pageIndex,
        size: this.pageSize,
      };
      if (this.$route.query.examType == 1) {
        data.schoolId = this.schoolId || "-1";
      }

      if (this.userInfo.roleId != 6) {
        data.schoolId = getStore({ name: "school_id" });
      }
      data.webId = this.webId;
      setStore({ name: "GradeQuality_info", content: data, type: 1 });
      this.listLoading = true;
      monitorQualityList(data)
        .then((res) => {
          this.listLoading = false;
          this.qualityList = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(() => {
          this.listLoading = true;
        });
    },
    setPaperList() {
      if (!this.examInformation || !this.GET_TEACHER_ROLE_LIST) {
        return;
      }
      let roleList = this.GET_TEACHER_ROLE_LIST.filter(
        (item) => item.subjectType == 9
      );
      let paperType = true;
      this.examInformation.paperList.map((item) => {
        roleList.forEach((index) => {
          if (index.subjectId == item.subjectId) {
            if (this.examPaperId == item.paperId) {
              paperType = false;
            }
            this.paperList.push({
              value: item.paperId,
              label: item.subjectName,
              subjectId: item.subjectId,
            });
          }
        });
      });
      if (paperType) {
        this.examPaperId = this.paperList[0].value;
      }
    },
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        if (this.userInfo.roleId == 5) {
          this.setPaperList();
        } else {
          this.examInformation.paperList.map((item) => {
            this.paperList.push({
              value: item.paperId,
              label: item.subjectName,
            });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.GradeQuality {
  padding: 24px;
  .mark-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .header-go-back {
      color: #5a5a5a;
      cursor: pointer;
    }
    .mark-title {
      span {
        color: $primary-color;
      }
    }
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .el-select {
      margin: 0 24px 0 8px;
    }
    .subject-name {
      width: 120px;
    }
  }
}
</style>
